@import "./icons.scss";

// Light Theme
@import "./bootstrap.scss";
@import "./app.scss";

// RTL
// @import "custom/rtl/general-rtl";
// @import "custom/rtl/bootstrap-rtl";
// @import "custom/rtl/spacing-rtl";
// @import "custom/rtl/float-rtl";
// @import "custom/rtl/text-rtl";
// @import "custom/rtl/structure-rtl";
// @import "custom/rtl/plugins-rtl";
// @import "custom/rtl/components-rtl";
// @import "custom/rtl/pages-rtl";

body {
	* {
		outline: none !important;
	}
}

[data-layout-mode="dark"] {
	#pagination button {
		background-color: #575757 !important;
		border: none !important;
	}

	.bg-grey {
		background-color: rgb(42 45 43) !important;
	}

	.text-secondary {
		color: rgb(161, 161, 161) !important;
	}

	.metismenu {
		background-color: #2c302e !important;
	}

	.css-1vvrvab {
		background-color: #2c302e !important;
	}
	.ps-menuitem-root {
		background-color: #2c302e !important;
	}
}

.bg-primary {
	background-color: #5156be !important;
}

.bg-yellow {
	background-color: #cd9b02 !important;
}

.vertical-menu {
	overflow-y: hidden !important;
}

.vertical-menu:hover {
	overflow-y: auto !important;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
	background-color: #fffbed !important;
	color: #cd9b02 !important;
}

a {
	color: #cd9b02;
}

.text-yellow {
	color: #cd9b02 !important;
}

/* close button **/
.close {
	background: transparent;
	border: 0;
	font-size: 10px;
	padding: 1.35rem 1.25rem;
	background: transparent escape-svg($btn-close-bg) center / $btn-close-width
		auto no-repeat;
	position: absolute;
	top: 0;
	right: 0;
	opacity: 0.5;
	width: 1em;
	height: 1em;
	z-index: 2;
	span {
		display: none;
	}
}

.logs {
	height: 25rem !important;
	overflow-y: auto;
}

.ReactModal__Overlay {
	z-index: 5000 !important;
}
.rangeslider__handle:focus {
	outline: none;
}
// React Table next css
.react-bootstrap-table-page-btns-ul {
	float: right !important;
}
.search-label {
	float: right;
}

/* rating**/
.rating-container {
	background-color: transparent !important;
}

/* form editor **/
.rdw-editor-main {
	border: 1px solid $gray-300;
	height: 239px;
	padding-left: 15px;
}
.dz-message {
	text-align: center;
	padding: 30px;
}

.react-datepicker-popper {
	z-index: 9999 !important;
}

.timeline {
	border-left: 3px solid #cd9b02;
	border-bottom-right-radius: 4px;
	border-top-right-radius: 4px;
	background: rgba(114, 124, 245, 0.09);
	margin: 0 auto;
	letter-spacing: 0.2px;
	position: relative;
	line-height: 1.4em;
	font-size: 1.03em;
	padding: 50px;
	list-style: none;
	text-align: left;
	max-width: 40%;
}

@media (max-width: 767px) {
	.timeline {
		max-width: 98%;
		padding: 25px;
	}
}

.timeline h1 {
	font-weight: 300;
	font-size: 1.4em;
}

.popover {
	border-radius: 0.7rem !important;
	box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px !important;
}

#showUserInfo li:hover {
	background-color: rgb(237, 237, 237) !important;
}

.expand .popover,
.expand .popover-inner,
.expand .popover-header {
	min-width: 50rem !important;
	width: 50rem !important;
	overflow-x: hidden !important;
}

.expand .popover-body {
	max-height: 25rem !important;
	overflow: auto;
	margin-top: 3rem !important;
	padding: 1rem !important;
}

.expand .popover-header {
	position: fixed !important;
}

.light-gray {
	background-color: rgb(241 241 241);
}

.timeline h2,
.timeline h3 {
	font-weight: 600;
	font-size: 1rem;
	margin-bottom: 10px;
}

.timeline .event {
	border-bottom: 1px dashed #e8ebf1;
	padding-bottom: 25px;
	margin-bottom: 25px;
	position: relative;
}

@media (max-width: 767px) {
	.timeline .event {
		padding-top: 30px;
	}

	.expand .popover,
	.expand .popover-inner,
	.expand .popover-header {
		min-width: auto !important;
		width: auto !important;
		overflow-x: hidden !important;
	}
}

.timeline .event:last-of-type {
	padding-bottom: 0;
	margin-bottom: 0;
	border: none;
}

.timeline .event:before,
.timeline .event:after {
	position: absolute;
	display: block;
	top: 0;
}

.timeline .event:before {
	left: -240px;

	content: attr(data-date);
	text-align: right;
	font-weight: 400;
	font-size: 0.9em;
	min-width: 120px;
}

@media (max-width: 767px) {
	.timeline .event:before {
		left: 0px;
		margin-bottom: 1rem !important;
		text-align: left;
	}

	.offcanvas-end {
		width: 95% !important;
	}
}

@media (min-width: 767px) {
	.offcanvas-end {
		margin-right: 2rem !important;
	}
}

#appealOffcanvas {
	border-radius: 1rem !important;
}

#FullScreenLoader {
	position: fixed;
	z-index: 99999 !important;
	height: 100vh;
	width: 100vw;
	overflow: show;
	margin: auto;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	background-color: rgba(224, 224, 224, 0.6);
}

#FullScreenLoader .loader {
	position: relative;
	top: 40%;
}

.offcanvas-end {
	top: 2rem;
	right: 0;
	width: 600px;
	max-height: 92% !important;
	height: fit-content !important;
	border-left: 1px solid #f6f6f6;
	transform: translateX(100%);
}

.offcanvas-end .card {
	background-color: #f8f8f8 !important;
}

.auth_input {
	width: 50px !important;
	height: 42px !important;
	padding: 8px !important;
	border-radius: 8px !important;
	font-size: 16px !important;
	text-align: center !important;
	border: 1px solid #ced4da !important;
	text-transform: uppercase !important;
}

#kyc_div img {
	cursor: zoom-in;
}

ul {
	list-style-type: none !important;
}

.timeline .event:after {
	-webkit-box-shadow: 0 0 0 3px #cd9b02;
	box-shadow: 0 0 0 3px #cd9b02;
	left: -55.8px;
	background: #fff;
	border-radius: 50%;
	height: 9px;
	width: 9px;
	content: "";
	top: 5px;
}

.frVPgl {
	position: relative;
	width: 100%;
	border-radius: inherit;
	overflow-x: auto;
	overflow-y: auto;
	min-height: 0;
	max-height: 681px !important;
	-webkit-overflow-scrolling: touch;
}

.scrollModal {
	max-height: 30rem;
	overflow-y: auto;
}

@media (max-width: 767px) {
	.timeline .event:after {
		left: -31.8px;
	}
}

.rtl .timeline {
	border-left: 0;
	text-align: right;
	border-bottom-right-radius: 0;
	border-top-right-radius: 0;
	border-bottom-left-radius: 4px;
	border-top-left-radius: 4px;
	border-right: 3px solid #cd9b02;
}

.rtl .timeline .event::before {
	left: 0;
	right: -170px;
}

.rtl .timeline .event::after {
	left: 0;
	right: -55.8px;
}

/* index.css */

::-webkit-scrollbar {
	width: 0.5rem !important;
	height: 0.5rem !important;
}

::-webkit-scrollbar-thumb {
	background-color: rgb(219, 219, 219);
}

[data-layout-mode="dark"] {
	::-webkit-scrollbar-thumb {
		background-color: rgb(110, 110, 110);
	}
}

.nav-tabs-custom .nav-item .nav-link.active {
	color: #cc9a02 !important;
}

select {
	background-color: rgb(243, 243, 243) !important;
	border: 0px !important;
}

.payment_card {
	width: 23rem !important;
}

.userDetailsBadge {
	top: 0.5rem !important;
	left: 95% !important;
}

#ordersTable .rdt_TableCell {
	min-height: 85px !important;
}

.badge {
	font-size: 0.7rem !important;
	font-weight: 900 !important;
}

.bg-info-subtle {
	background-color: #d5edff !important;
	border: 1px solid #008df8 !important;
}

.nav-tabs-custom .nav-item .nav-link::after {
	content: "";
	background: #cc9a02 !important;
	height: 1.5px;
	position: absolute;
	width: 100%;
	left: 0;
	bottom: -0.5px;
	transition: all 250ms ease 0s;
	transform: scale(0);
}

#personal_info .icon-demo-content i {
	border: none !important;
	background-color: #f1f1f1 !important;
}

.nav-tabs-custom {
	overflow-x: auto !important;
	overflow-y: auto !important;
}

.nav-tabs-custom::-webkit-scrollbar {
	display: none;
}

.timeline_area {
	position: relative;
	z-index: 1;
}
.single-timeline-area {
	position: relative;
	z-index: 1;
	padding-left: 180px;
}
@media only screen and (max-width: 575px) {
	.single-timeline-area {
		padding-left: 100px;
	}
}
.single-timeline-area .timeline-date {
	position: absolute;
	width: 180px;
	height: 100%;
	top: 0;
	left: 0;
	z-index: 1;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	-ms-grid-row-align: center;
	align-items: center;
	-webkit-box-pack: end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	padding-right: 60px;
}
@media only screen and (max-width: 575px) {
	.single-timeline-area .timeline-date {
		width: 100px;
	}
}
.single-timeline-area .timeline-date::after {
	position: absolute;
	width: 3px;
	height: 100%;
	content: "";
	background-color: #ebebeb;
	top: 0;
	right: 30px;
	z-index: 1;
}
.single-timeline-area .timeline-date::before {
	position: absolute;
	width: 11px;
	height: 11px;
	border-radius: 50%;
	background-color: #f1c40f;
	content: "";
	top: 50%;
	right: 26px;
	z-index: 5;
	margin-top: -5.5px;
}
.single-timeline-area .timeline-date p {
	margin-bottom: 0;
	color: #020710;
	font-size: 13px;
	text-transform: uppercase;
	font-weight: 500;
}
.single-timeline-area .single-timeline-content {
	position: relative;
	z-index: 1;
	padding: 30px 30px 25px;
	border-radius: 6px;
	margin-bottom: 15px;
	margin-top: 15px;
	-webkit-box-shadow: 0 0.25rem 1rem 0 rgba(47, 91, 234, 0.125);
	box-shadow: 0 0.25rem 1rem 0 rgba(47, 91, 234, 0.125);
	border: 1px solid #ebebeb;
}
@media only screen and (max-width: 575px) {
	.single-timeline-area .single-timeline-content {
		padding: 20px;
	}
}
.single-timeline-area .single-timeline-content .timeline-icon {
	-webkit-transition-duration: 500ms;
	transition-duration: 500ms;
	width: 30px;
	height: 30px;
	background-color: #f1c40f;
	-webkit-box-flex: 0;
	-ms-flex: 0 0 30px;
	flex: 0 0 30px;
	text-align: center;
	max-width: 30px;
	border-radius: 50%;
	margin-right: 15px;
}
.single-timeline-area .single-timeline-content .timeline-icon i {
	color: #ffffff;
	line-height: 30px;
}
.single-timeline-area .single-timeline-content .timeline-text h6 {
	-webkit-transition-duration: 500ms;
	transition-duration: 500ms;
}
.single-timeline-area .single-timeline-content .timeline-text p {
	font-size: 13px;
	margin-bottom: 0;
}
.single-timeline-area .single-timeline-content:hover .timeline-icon,
.single-timeline-area .single-timeline-content:focus .timeline-icon {
	background-color: #020710;
}
.single-timeline-area .single-timeline-content:hover .timeline-text h6,
.single-timeline-area .single-timeline-content:focus .timeline-text h6 {
	color: #3f43fd;
}

.folder {
	width: min-content;
	margin: auto;
	animation: float 2s infinite linear;
}

.folder .top {
	background-color: #ff8f56;
	width: 60px;
	height: 12px;
	border-top-right-radius: 10px;
}

.folder .bottom {
	background-color: #ffce63;
	width: 100px;
	height: 70px;
	box-shadow: 5px 5px 0 0 #283149;
	border-top-right-radius: 8px;
}

.container .title {
	font-size: 0.9em;
	color: #283149;
	text-align: center;
	margin-top: 15px;
}

.paginate button {
	border: none !important;
	background-color: rgb(133, 133, 133) !important;
}

.searchInputWrapper {
	position: relative;
}

.searchInput {
	width: 20rem;
	height: 2rem;
	padding: 0 1rem;
	border-radius: 2rem;
	border: none;
	transition: transform 0.1s ease-in-out;
}

::placeholder {
	color: #a1a1a1;
}

/* hide the placeholder text on focus */
:focus::placeholder {
	text-indent: -999px;
}

.searchInputIcon {
	position: absolute;
	right: 0.8rem;
	top: 0.5rem;
	color: #a1a1a1;
	transition: all 0.1s ease-in-out;
}

#react_table tbody tr {
	cursor: pointer;
}

@keyframes float {
	0% {
		transform: translatey(0px);
	}

	50% {
		transform: translatey(-25px);
	}

	100% {
		transform: translatey(0px);
	}
}

.search-box .search-icon-search {
	font-size: 16px !important;
	position: absolute !important;
	left: 13px !important;
	top: 0 !important;
	line-height: 38px !important;
}

//Two verification Page
.verification {
	input {
		&:focus {
			border: none;
			outline: none !important;
		}
	}
}

@media (max-width: 768px) {
	.verification {
		input {
			width: 30px !important;
		}
	}
}

@media (max-width: 320px) {
	.verification {
		input {
			width: 30px !important;
			height: 30px !important;
			font-size: 15px !important;
		}
	}
}

.rangeslider-horizontal .rangeslider__fill {
	background-color: #5156be !important;
}
.preview-thumbsnav {
	display: flex !important;
	list-style-type: none;
}
.flatpickr-current-month .flatpickr-monthDropdown-months,
.flatpickr-months .flatpickr-month,
.flatpickr-weekdays,
span.flatpickr-weekday {
	background: #5156be !important;
}
.flatpickr-calendar.arrowBottom:after {
	border-top-color: #5156be !important;
}
.flatpickr-calendar.arrowTop:after {
	border-bottom-color: #5156be !important;
}

// Flatepicker
.flatpickr-months,
.flatpickr-weekdays,
.flatpickr-monthDropdown-months {
	background-color: #42a5f5 !important;
}
.flatpickr-current-month .flatpickr-monthDropdown-months,
.flatpickr-months .flatpickr-month,
.flatpickr-weekdays,
span.flatpickr-weekday {
	background-color: #42a5f5 !important;
}

.search-box .search-icon {
	font-size: 16px;
	position: absolute;
	left: 13px;
	top: 0;
	line-height: 38px !important;
}
.carousel-indicators button,
.carousel-indicators li {
	-webkit-box-sizing: content-box;
	box-sizing: content-box;
	-webkit-box-flex: 0;
	-ms-flex: 0 1 auto;
	flex: 0 1 auto;
	padding: 0;
	margin-right: 3px;
	margin-left: 3px;
	text-indent: -999px;
	cursor: pointer;
	background-color: #fff;
	background-clip: padding-box;
	-webkit-transition: opacity 0.6s ease;
	transition: opacity 0.6s ease;
	width: 30px !important;
	height: 3px !important;
}
.dashboard-slider.carousel-indicators li {
	width: 10px !important;
	height: 10px !important;
	border-radius: 50% !important;
}

.dashboard-slider.carousel-indicators {
	position: relative;
}
#reviewcarouselIndicators .carousel-indicators {
	margin-left: 0;
	float: left;
}

#pagination button {
	background-color: #f3f3f2 !important;
	border: none !important;
}

.rdt_TableHeadRow {
	font-size: 0.9rem !important;
	color: #313533 !important;
	font-weight: 700 !important;
}

.rdt_TableRow:nth-child(even) {
	background-color: #efefef !important;
}

.disabled_badge {
	border: 1px solid #dc3545;
	border-radius: 0.3rem;
	color: #dc3545;
	background-color: #ffeeef;
	font-weight: 700;
	box-shadow: none;
}
.disabled_badge:hover {
	border: 1px solid #dc3545;
	border-radius: 0.3rem;
	color: #dc3545;
	background-color: #ffeeef;
	font-weight: 700;
	box-shadow: none;
}

.active_badge {
	border: 1px solid #188755;
	border-radius: 0.3rem;
	color: #188755;
	background-color: #e7fff4;
	font-weight: 700;
	box-shadow: none;
}
.active_badge:hover {
	border: 1px solid #188755;
	border-radius: 0.3rem;
	color: #188755;
	background-color: #e7fff4;
	font-weight: 700;
	box-shadow: none;
}

.rounded-border {
	align-items: center;
	border: 1px solid #e9e9ef;
	border-radius: 50%;
	color: gray;
	display: inline-flex;
	font-size: 20px;
	height: 40px;
	justify-content: center;
	margin-right: 16px;
	transition: all 0.4s;
	vertical-align: middle;
	width: 40px;
}

[data-layout-mode="dark"] .rounded-border {
	align-items: center;
	border: 1px solid #474747;
	border-radius: 50%;
	color: rgb(244, 244, 244);
	display: inline-flex;
	font-size: 20px;
	height: 40px;
	justify-content: center;
	margin-right: 16px;
	transition: all 0.4s;
	vertical-align: middle;
	width: 40px;
}
[data-layout-mode="dark"] .rdt_TableHeadRow {
	background-color: rgb(79 79 79) !important;
	color: white !important;
}

[data-layout-mode="dark"] .rdt_TableRow {
	background-color: rgb(42 45 44) !important;
	color: rgb(155, 155, 155) !important;
}

.modal-content {
	border-radius: 0.5rem !important;
}

.rounded-border:hover {
	background-color: rgba(81, 86, 190, 0.2);
	color: #5156be;
}

.info_badge {
	border: 1px solid #0d6ffc;
	border-radius: 0.3rem;
	color: #0d6ffc;
	background-color: #e7fff4;
	font-weight: 700;
	box-shadow: none;
}
.active_badge:hover {
	border: 1px solid #0d6ffc;
	border-radius: 0.3rem;
	color: #0d6ffc;
	background-color: #e7fff4;
	font-weight: 700;
	box-shadow: none;
}

.nav_active .ps-menu-label,
.nav_active .ps-menu-icon {
	color: #cd9b02 !important;
}

.nav_active {
	background-color: #fffbed !important;
}

.spinner {
	font-size: 28px;
	position: relative;
	display: inline-block;
	width: 1em;
	height: 1em;
}

#password_input {
	position: relative !important;
	width: 2.5rem;
}
#password_input div {
	position: absolute !important;
}

select:disabled {
	opacity: 0.6 !important;
}

.dashbaord_ticket {
	height: 6rem;
	overflow-y: auto;
}

.showBalance {
	max-height: 20rem !important;
	overflow-y: auto;
}

.modal-content {
	z-index: 999999 !important;
}

.carousel-indicators [data-bs-target] {
	background-color: #a3a3a3 !important;
}

.bg-light-grey {
	background-color: #f4f4f4 !important;
}

.totalBalance {
	height: 17rem;
	overflow-y: auto;
}

.react-select__menu {
	z-index: 99999 !important;
}

.tooltip,
.popover {
	z-index: 999 !important;
}

.activityLogs .step-icon {
	background-color: rgba(205, 154, 2, 0.244);
	border-color: rgb(205, 155, 2);
	color: rgb(205, 155, 2);
	border: 1px solid rgb(205, 155, 2);
	border-radius: 50%;
	display: inline-block;
	font-size: 18px;
	height: 40px;
	line-height: 37px;
	position: relative;
	text-align: center;
	width: 40px;
	z-index: 1;
}

.bg-grey {
	background-color: rgb(247 247 247) !important;
	min-height: 100vh !important;
}

.spinner.center {
	margin: 40px 0px;
}

.hover :hover {
	background-color: rgb(244, 244, 244) !important;
	border-radius: 0.5rem;
}

.selected {
	background-color: rgb(244, 244, 244) !important;
	border-radius: 0.5rem;
}

.spinner .spinner-blade {
	position: absolute;
	left: 0.4629em;
	bottom: 0;
	width: 0.074em;
	height: 0.2777em;
	border-radius: 0.0555em;
	background-color: transparent;
	-webkit-transform-origin: center -0.2222em;
	-ms-transform-origin: center -0.2222em;
	transform-origin: center -0.2222em;
	animation: spinner-fade9234 1s infinite linear;
}

.spinner .spinner-blade:nth-child(1) {
	-webkit-animation-delay: 0s;
	animation-delay: 0s;
	-webkit-transform: rotate(0deg);
	-ms-transform: rotate(0deg);
	transform: rotate(0deg);
}

.spinner .spinner-blade:nth-child(2) {
	-webkit-animation-delay: 0.083s;
	animation-delay: 0.083s;
	-webkit-transform: rotate(30deg);
	-ms-transform: rotate(30deg);
	transform: rotate(30deg);
}

.spinner .spinner-blade:nth-child(3) {
	-webkit-animation-delay: 0.166s;
	animation-delay: 0.166s;
	-webkit-transform: rotate(60deg);
	-ms-transform: rotate(60deg);
	transform: rotate(60deg);
}

.spinner .spinner-blade:nth-child(4) {
	-webkit-animation-delay: 0.249s;
	animation-delay: 0.249s;
	-webkit-transform: rotate(90deg);
	-ms-transform: rotate(90deg);
	transform: rotate(90deg);
}

.spinner .spinner-blade:nth-child(5) {
	-webkit-animation-delay: 0.332s;
	animation-delay: 0.332s;
	-webkit-transform: rotate(120deg);
	-ms-transform: rotate(120deg);
	transform: rotate(120deg);
}

.spinner .spinner-blade:nth-child(6) {
	-webkit-animation-delay: 0.415s;
	animation-delay: 0.415s;
	-webkit-transform: rotate(150deg);
	-ms-transform: rotate(150deg);
	transform: rotate(150deg);
}

.spinner .spinner-blade:nth-child(7) {
	-webkit-animation-delay: 0.498s;
	animation-delay: 0.498s;
	-webkit-transform: rotate(180deg);
	-ms-transform: rotate(180deg);
	transform: rotate(180deg);
}

.spinner .spinner-blade:nth-child(8) {
	-webkit-animation-delay: 0.581s;
	animation-delay: 0.581s;
	-webkit-transform: rotate(210deg);
	-ms-transform: rotate(210deg);
	transform: rotate(210deg);
}

.spinner .spinner-blade:nth-child(9) {
	-webkit-animation-delay: 0.664s;
	animation-delay: 0.664s;
	-webkit-transform: rotate(240deg);
	-ms-transform: rotate(240deg);
	transform: rotate(240deg);
}

.spinner .spinner-blade:nth-child(10) {
	-webkit-animation-delay: 0.747s;
	animation-delay: 0.747s;
	-webkit-transform: rotate(270deg);
	-ms-transform: rotate(270deg);
	transform: rotate(270deg);
}

.spinner .spinner-blade:nth-child(11) {
	-webkit-animation-delay: 0.83s;
	animation-delay: 0.83s;
	-webkit-transform: rotate(300deg);
	-ms-transform: rotate(300deg);
	transform: rotate(300deg);
}

.spinner .spinner-blade:nth-child(12) {
	-webkit-animation-delay: 0.913s;
	animation-delay: 0.913s;
	-webkit-transform: rotate(330deg);
	-ms-transform: rotate(330deg);
	transform: rotate(330deg);
}

@keyframes spinner-fade9234 {
	0% {
		background-color: #69717d;
	}

	100% {
		background-color: transparent;
	}
}

.ps-menu-icon {
	margin-right: 0.2rem !important;
}

.ps-menu-label,
.ps-menu-icon {
	color: #6c7181;
}

.kjtkaj:not(:last-of-type) {
	border: none !important;
}

.vertical-menu {
	overflow-y: auto;
	overflow-x: hidden;
}

.rdt_TableCell {
	font-size: 0.85rem !important;
	height: 60px !important;
}

#stakingPlans .rdt_TableCell {
	font-size: 0.85rem !important;
	height: 77px !important;
}
#allStakings .rdt_TableCell {
	font-size: 0.85rem !important;
	height: 77px !important;
}

.bx {
	font-weight: 400;
}
.sweet-alert h2 {
	font-size: 22px !important;
	font-weight: 500 !important;
}
.dropdown-toggle::after,
.dropstart .dropdown-toggle::before {
	display: none !important;
}
//react-table
.custom-header-css {
	thead {
		tr {
			th {
				div:last-child {
					min-height: 38px;
				}
			}
		}
		td:nth-last-child(2) {
			width: 150px !important;
		}
	}
}
.carousel-indicators [data-bs-target] {
	font-size: 0;
}

#managePermissionModal {
	max-height: 25rem !important;
	overflow-y: auto;
}

.form-check-input:checked {
	background-color: #cd9b02 !important;
	border-color: #cd9b02 !important;
}

.bg-extralight {
	background-color: rgb(240, 240, 240) !important;
}

.range-slider .range-slider__thumb {
	position: absolute;
	z-index: 3;
	top: 50%;
	width: 15px !important;
	height: 15px !important;
	transform: translate(-50%, -50%);
	border-radius: 50%;
	background: #cc9a02 !important;
}

.auth-page .row {
	background-image: url("../../../public/images/auth-bg.avif");
}
.auth-page .card {
	box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px;
}

.hover-underline:hover {
	border-bottom: 2px dotted #74788d !important;
}

.range-slider .range-slider__range {
	position: absolute;
	z-index: 1;
	transform: translate(0, -50%);
	top: 50%;
	width: 100%;
	height: 100%;
	background: #cc9a02 !important;
}

#rangeBox {
	min-width: 15rem !important;
}

.sample-drag-bar {
	flex-shrink: 0;
	width: 5px;
	background-color: #eaeaea;
	cursor: col-resize;
	transition: background-color 0.15s 0.15s ease-in-out;
}
.sample-drag-bar::after {
	content: "";
	background-image: url("../../../public/images/resize.png");
	background-size: contain;
	width: 30px;
	height: 30px;
	display: block;
	left: -12px;
	top: 50%;
	position: relative;
	z-index: 9999 !important;
}

#codeBox {
	height: 100vh !important;
	overflow: auto;
}

#chatgpt {
	position: absolute;
	right: 7px;
	top: 15%;
}

#suggestion-box {
	background-color: white;
	width: 100%;
	height: 10rem;
	position: absolute;
	top: -170px;
	overflow: auto;
}

.sample-drag-bar.sample-drag-bar--dragging,
.sample-drag-bar:hover {
	background-color: #cc9a02;
}

.sample-drag-bar.sample-drag-bar--horizontal {
	height: 5px;
	width: 100%;
	cursor: row-resize;
}

#resize-wrapper .container {
	width: 100% !important;
}
#resize-wrapper .footer {
	display: none !important;
}
